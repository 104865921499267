<template>
  <div>
    <div class="lg:bg-hero-pattern lg:bg-repeat-round bg-repeat-space lg:h-screen">
      <div class="lg:bg-white bg-gray-200 lg:bg-opacity-50 sm:p-20 p-5 content-center lg:h-screen">
        <ContactPanel />
      </div>
    </div>  
  </div>
</template>

<script>
import ContactPanel from '../assets/Contact/ContactPanel'

export default {
  name: 'Contact',
  components: {
    ContactPanel
  },
  data() {
    return {
      
    }
  },
  mounted() {
    setTimeout(function(){
        var replacers = document.querySelectorAll('[data-replace]');
        for(var i=0; i<replacers.length; i++){
            console.log('hit here2');
            let replaceClasses = JSON.parse(replacers[i].dataset.replace.replace(/'/g, '"'));
            Object.keys(replaceClasses).forEach(function(key) {
                replacers[i].classList.remove(key);
                replacers[i].classList.add(replaceClasses[key]);
            });
        }
    }, 1);
  },
}
</script>