<template>
  <div>
    <div class="lg:w-full lg:h-screen xl:h-full bg-center bg-cover bg-hero-pattern">
      <div class="lg:bg-white lg:h-screen xl:h-full bg-gray-200 lg:bg-opacity-50 sm:p-20 p-5 content-center">
        <ProjectBox v-for="project in Projects" :key="project" v-bind:projectName=project.title v-bind:projectImages=project.images v-bind:projectDescription=project.description v-bind:projectEnum=project.projectEnum  v-bind:enumTitle=project.enumTitle class="sm:mb-24 font-mono" />
      </div>
    </div>  
  </div>
</template>

<script>
import ProjectBox from '../assets/Projects/ProjectBox'

export default {
  name: 'Project',
  template: `<h1>asd</h1>`,
  components: {
    ProjectBox
  },
  data() {
    return {
      Projects: {
        SensiBlue: {
        title: 'Compartimentări cu pereți de sticlă',
        images: [],
        description: 'Fixarea sticlei se realizează cu profile din aluminiu în diverse culori si cu profile din inox.',
        enumTitle: 'Avantaje:',
        projectEnum: ['Transparență, lasă lumina naturală să patrundă în spații largi', 'Design deosebit, personalizat', 'Compartimentarea spațiului în funcție de necesități', 'Ușor de întreținut', 'Ușor și rapid de montat', 'Sticla poate sa fie clară, sablată, colorată sau chiar printată în diverse modele, etc'],
      },

      PennyMarket: {
        title: 'Pereţi Cortină',
        images: [],
        description: 'Pereții cortină se pot realiza într-o gamă variată de culori și forme, permițând integrarea cu ușurință a ferestrelor, ușilor precum și a celorlalte sisteme, clasice sau moderne cum ar fi panouri fotovoltaice sau a altor sisteme de acoperiș. Există mai multe tipuri de pereți cortină de sine statători sau placați pe structuri din diferite materiale, cum ar fi betonul, cărămida, lemnul sau oțelul, având rama metalică (de aluminiu, de regulă) vizibilă sau mascată.',
        enumTitle: 'Pereții cortină câștigă din ce în ce mai mult teren în fața construcțiilor clasice datorită avantajelor importante pe care acestea le prezintă: ',
        projectEnum: ['calități excelente de izolare termică și fonică', 'luminozitate sporită', 'rezistență mare a ansamblului', 'timp mult mai scurt de execuție', 'aspectul elegant și modern']
      },

      Balustrade: {
        title: 'Balustrade din sticlă securizată',
        images: [],
        description: 'Destinate fiind locuinţelor şi birourilor, fixarea sticlei se realizează cu profile din aluminiu în diverse culori si cu profile din inox.',
        enumTitle: '',
        projectEnum: ['Transparență, lasă lumina naturală să patrundă, dând senzația de spațiu deschis', 'Design deosebit, personalizat', 'Ușor de întreținut', 'Ușor și rapid de montat', 'Sticla poate sa fie clară, sablată, colorată sau chiar printată în diverse modele, etc']
      },

      Civile: {
        title: 'Construcţii Civile',
        images: [],
        description: 'Firma noastră efectuează diverse tipuri de lucrări pentru construcţiile civile:',
        enumTitle: '',
        projectEnum: ['Construcţii case la roşu', 'Construcţii mansarde la bloc', 'Construcţii case la cheie', 'Amenajări interioare - tencuieli clasice sau mecanizate, şape, placări cu gresie şi faianţă, placare cu rigips, zugrăveli simple sau decorative, montaj parchet, instalaţii centrale termice, execuţii instalaţii electrice, execuţie instalaţii tehnico-sanitare', 'Amenajări exterioare - placări, izolaţii, temroizolări, tencuieli, împrejmuiri, zugrăveli.', 'Montaj tâmplărie PVC, aluminiu sau lemn stratificat',]
      },

      Industriale: {
        title: 'Construcţii Industriale',
        images: [],
        description: 'Structuri realizate din profile europene, profile standard, profile ambutisate sau din elemente compuse din table sudate.',
        enumTitle: '',
        projectEnum: ['Construcţii de tip hală industrială', 'Clădire administrativă sau comericală']
      },

      Mentenant: {
        title: 'Lucrări de Mentenanţă',
        images: [],
        description: 'Societatea noastră poate asigura mentenanţă oricărui tip de clădire pentru a evita neplăcerile şi discomfortul proprietarilor sau beneficiarilor spaţiilor respective',
        enumTitle: '',
        projectEnum: ['Începând cu anul 2006 asigurăm mentenanţa depozitelor Mediplus (Grup A & D Pharma).', 'Între anii 2006 - 2013 farmaciile Sensiblu din zona Sud-Est au beneficiat de mentenanţă oferită de societatea noastră.', 'Alături de compania Mercadia am efecutat lucrări de mentenanţă şi dezafectare pentru magazinele Mic.Ro şi Minimax.']
      }
      
      },     
      images: [],
    }
  }, 
  mounted() {
    this.importAll(require.context('../../public/constructions/PeretiSticla/', true, /\.jpg$/), this.Projects.SensiBlue, './constructions/PeretiSticla/');
    this.importAll(require.context('../../public/constructions/Penny/', true, /\.jpg$/), this.Projects.PennyMarket, './constructions/Penny/');
    this.importAll(require.context('../../public/constructions/Balustrade/', true, /\.jpg$/), this.Projects.Balustrade, './constructions/Balustrade/');
    this.importAll(require.context('../../public/constructions/Hale/', true, /\.jpg$/), this.Projects.Industriale, './constructions/Hale/');
    this.importAll(require.context('../../public/constructions/Civile/', true, /\.jpg$/), this.Projects.Civile, './constructions/Civile/');

    setTimeout(function(){
        var replacers = document.querySelectorAll('[data-replace]');
        for(var i=0; i<replacers.length; i++){
            console.log('hit here2');
            let replaceClasses = JSON.parse(replacers[i].dataset.replace.replace(/'/g, '"'));
            Object.keys(replaceClasses).forEach(function(key) {
                replacers[i].classList.remove(key);
                replacers[i].classList.add(replaceClasses[key]);
            });
        }
    }, 1);
  },
  methods: {
    importAll(r, context, keyPath) {
      r.keys().forEach(key => (context.images.push(keyPath + key)));
      
    },
  },
}
</script>