<template>
<!-- This is an example component -->
<div class="container mx-auto my-5">

    <div class="relative rounded-lg flex flex-col md:flex-row items-center md:shadow-xl md:h-96 mx-2">
        
        <div class="z-0 order-1 md:order-2 relative w-full h-auto md:w-2/5 h-80 md:h-full overflow-hidden rounded-lg md:rounded-none md:rounded-r-lg">
            <div class="absolute inset-0 w-full h-full object-fill object-center bg-blue-600 sm:bg-white bg-cover bg-bottom"></div>
            <div class="md:hidden absolute inset-0 h-full p-6 pb-6 flex flex-col-reverse justify-start items-start bg-gradient-to-b from-transparent via-transparent to-gray-900">
            </div>
            <svg class="z-10 hidden md:block absolute inset-y-0 h-full w-24 fill-current text-white -ml-12" viewBox="0 0 100 100" preserveAspectRatio="none">
                <polygon points="50,0 100,0 50,100 0,100" />
            </svg>
            <swiper class="swiper" :options="swiperOption">
              <swiper-slide v-for="image in projectImages" :key="image">
                  <img class="rounded-lg" v-bind:src=image />
                  </swiper-slide>
              <div class="swiper-button-prev" slot="button-prev"></div>
              <div class="swiper-button-next" slot="button-next"></div>
          </swiper>
        </div>

        <div class="overflow-auto z-10 order-2 md:order-1 w-full h-full md:w-3/5 flex items-center -mt-6 md:mt-0 bg-transparent md:bg-white">
            <div class="p-8 md:pr-18 md:pl-14 md:py-12 mx-2 md:mx-0 h-full bg-white rounded-lg md:rounded-none md:rounded-l-lg shadow-xl md:shadow-none w-full">
                <h3 class="block font-bold text-2xl text-gray-700 mb-4">{{projectName}}</h3>
                <p class="text-gray-600 text-justify">{{projectDescription}}</p><br />
                <div class="px-10 py-5" v-if="projectEnum">
                    <ul class="text-justify list-disc">
                    <li v-for="enumItem in projectEnum" :key="enumItem">{{enumItem}}</li>
                    </ul>
                </div>
            </div>
        </div>

    </div>
</div>
    
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

//import 'swiper/swiper-bundle.css'
import 'swiper/css/swiper.css'
// import './base.scss';

export default {
  name: 'ProjectBoxTest',
  components: {
      Swiper,
      SwiperSlide
  },
  props: {
    projectName: String,
    projectImages: [],
    projectDescription: String,
    projectEnum: [],
    enumTitle: String
  },
  data() {
     return {
        swiperOption: {
          pagination: {
            el: '.swiper-pagination',
            type: 'progressbar'
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          }
        }
    }
  },
}
</script>

<style lang="scss" scoped>
  
</style>