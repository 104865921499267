<template>
    <div class="bg-white bg-opacity-70 shadow-xl rounded-lg lg:p-10">
        <h1 class="text-xl font-bold mb-4">Contact</h1>
        <ContactPerson v-bind:contactName=contactOne.name v-bind:contactInfo=contactOne.contactInfo />
        <div class="mt-10">
          <h1 class="text-xl font-bold mb-4">Informatii Generale</h1>
          <p>Locaţie: Bulevardul George Coşbuc, nr. 408 - Judetul Galaţi</p>
          <p>Telefon/ Fax: 0236 43 63 70</p>
          <p>E-mail: sowiens@yahoo.com, office@sowiens.ro</p>
        </div>
    </div>
</template>

<script>
import ContactPerson from './ContactPerson'
export default {
  name: 'ContactPanel',
  components: {
    ContactPerson
  },
  props: {

  },
  data() {
     return {
        contactOne: {
            name: 'Beteringhe Romeo',
            contactInfo: ['Administrator', 'Telefon: 0744 517 283']
          }
    }
  }
}
</script>
