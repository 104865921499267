<template>
    <div class="mb-5">
      <!-- <div class="relative">
        <img v-bind:src=contactPhoto class="rounded-full border border-gray-100 bg-white shadow-sm mb-2 absolute h-32 w-32 object-cover" />
      </div> -->
      <div>
        <h2 class="text-lg">{{contactName}}</h2>
        <p v-for="info in contactInfo" :key="info" class="text-center">{{info}}</p>
      </div>
    </div>
</template>

<script>
export default {
  name: 'ContactPerson',
  props: {
    contactName: String,
    contactInfo: []
  }
}
</script>
